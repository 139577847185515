<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style lang="scss">
body {
  background-color: #dfe8ee;
}
.v-toast {
  z-index: 999999 !important;
}
</style>

<script>
export default {
  components: {},
  mounted() {}
};
</script>