import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    name: 'home',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/registros',
    name: 'registros',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/equipamentos',
    name: 'equipamentos',
    component: () => import('../views/EquipamentosView.vue')
  },
  {
    path: '/grupos',
    name: 'grupos',
    component: () => import('../views/GruposView.vue')
  },
  {
    path: '/configurar-grupo',
    name: 'grupos',
    component: () => import('../views/ConfigurarGrupoView.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import('../views/EventosView.vue')
  },
  {
    path: '/nova-instalacao',
    name: 'nova-instalacao',
    component: () => import('../views/NovaInstalacao.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
